import React, { useState } from "react";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
} from "framer-motion";
import { useWindowSize } from "react-use";
import { Desktop, Tablet } from "../../constants/responsive";
import JasmineBlinkingEyes from "../../images/jasmine/jasmine-blinking-eyes.gif";

const Jasmine = ({ fluid }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { scrollY } = useViewportScroll();
  const { width, height } = useWindowSize();
  const top = useTransform(scrollY, (y) => {
    if (width >= 1200) {
      return (-800 - y - height / 20) / 8;
    } else if (width > 768 && width < 1200) {
      return (5800 - y - height / 2) / 16;
    } else {
      return 560 - y / 11 - height / 32;
    }
  });
  const topSpring = useSpring(top, { stiffness: 400, damping: 90 });

  return (
    <motion.div
      css={css`
        min-width: 280px;
        margin-bottom: 40px;
        user-select: none;
        transition: opacity 0.3s ease;
        opacity: ${isVisible ? 1 : 0};
        position: absolute;
        z-index: 1;
        width: 620px;
        right: -26%;
        ${Tablet} {
          max-width: none;
          width: 850px;
          position: absolute;
          z-index: 1;
          top: 20px;
          left: 0;
          transform: translateX(-60px);
        }
        ${Desktop} {
          width: 1200px;
          left: 35%;
          transform: translateX(200px);
        }
      `}
      style={{
        top: topSpring,
      }}
    >
      <div
        css={css`
          position: relative;
          z-index: 1;
        `}
      >
        <Img
          fluid={fluid}
          loading="eager"
          fadeIn={false}
          onLoad={() => setIsVisible(true)}
        />
      </div>
      <img
        src={JasmineBlinkingEyes}
        alt="jasmine-blinking-eyes"
        css={css`
          position: absolute;
          width: 31.5%;
          top: 31%;
          right: 34%;
          z-index: 2;
          transition: opacity 0.3s ease;
          opacity: ${isVisible ? 1 : 0};
        `}
      />
    </motion.div>
  );
};

export default Jasmine;
