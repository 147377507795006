import React, { useRef, useState } from "react";
import { css } from "@emotion/core";
import { useMount, useUnmount } from "react-use";

const FadeInSection = ({ children }) => {
  const domRef = useRef();
  const observer = useRef();

  const [isVisible, setVisible] = useState(false);

  const observerCallback = (entries) => {
    if (
      (entries[0].isIntersecting && entries[0].boundingClientRect.top > 0) ||
      entries[0].boundingClientRect.top < 0
    ) {
      setVisible(true);
    }
  };

  useMount(() => {
    observer.current = new IntersectionObserver(observerCallback);
    observer.current.observe(domRef.current);
    if (isVisible) observer.current.unobserve(domRef.current);
  });

  useUnmount(() => {
    observer.current = new IntersectionObserver(observerCallback);
    observer.current.unobserve(domRef.current);
  });

  return (
    <div
      css={css`
        & .is-visible {
          opacity: 1;
          transform: none;
          visibility: visible;
        }
      `}
    >
      <div
        ref={domRef}
        css={css`
          opacity: 0;
          transform: translate(0, 30%);
          visibility: hidden;
          transition: opacity 400ms ease, transform 400ms ease;
          will-change: opacity, visibility;
        `}
        className={isVisible ? " is-visible" : ""}
      >
        {children}
      </div>
    </div>
  );
};

export default FadeInSection;
