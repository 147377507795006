import React from "react";
import { css } from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-gtag";
import { Desktop, Tablet } from "../../constants/responsive";

const Media = ({ children, width = [60, 60, 60], href }) => {
  return (
    <OutboundLink
      href={href}
      target="_blank"
      rel="noreferrer"
      css={css`
        width: ${width[0]}px;
        margin: 20px;
        ${Tablet} {
          width: ${width[1]}px;
          margin: 0px;
        }
        ${Desktop} {
          width: ${width[2]}px;
        }
      `}
    >
      {children}
    </OutboundLink>
  );
};

export default Media;
