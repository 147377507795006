import React, { useState } from "react";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
} from "framer-motion";
import { useWindowSize } from "react-use";
import { Desktop, Tablet } from "../../constants/responsive";
import PortalBlinkingEyes from "../../svgs/eyes/portal-blinking-eyes.svg";

const Portal = ({ fluid }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { scrollY } = useViewportScroll();
  const { width, height } = useWindowSize();
  const top = useTransform(scrollY, (y) => {
    if (width >= 1200) {
      return (-7400 - (y + height) / 2) / 8;
    }
    if (width >= 768 && width < 1200) {
      return (6800 - y - height / 2) / 12;
    } else {
      return 740 - y / 10 - height / 32;
    }
  });
  const topSpring = useSpring(top, { stiffness: 400, damping: 90 });
  return (
    <motion.div
      css={css`
        min-width: 280px;
        max-width: none;
        margin-bottom: 40px;
        user-select: none;
        transition: opacity 0.3s ease;
        opacity: ${isVisible ? 1 : 0};
        width: 1000px;
        position: absolute;
        z-index: 1;
        right: -74.5%;
        ${Tablet} {
          width: 850px;
          position: absolute;
          z-index: 1;
          top: 10px;
          right: 6.5%;
          transform: translateX(110px);
        }
        ${Desktop} {
          width: 1450px;
          right: -6%;
          transform: translateX(-200px);
        }
      `}
      style={{
        top: topSpring,
      }}
    >
      <div
        css={css`
          position: relative;
          z-index: 1;
        `}
      >
        <Img
          fluid={fluid}
          loading="eager"
          fadeIn={false}
          onLoad={() => setIsVisible(true)}
        />
      </div>
      <div
        css={css`
          position: absolute;
          width: 33%;
          top: 5%;
          right: 33.5%;
          z-index: 2;
          transition: opacity 0.3s ease;
          opacity: ${isVisible ? 1 : 0};
        `}
      >
        <PortalBlinkingEyes
          css={css`
            width: 100%;
          `}
        />
      </div>
    </motion.div>
  );
};

export default Portal;
