import React from "react";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import Storage from "../../svgs/icons/storage.svg";
import Multimedia from "../../svgs/icons/multimedia.svg";
import SMS from "../../svgs/icons/sms.svg";
import RobotFeature from "../RobotFeature";

const RiceFeatures = () => {
  return (
    <>
      <RobotFeature
        icon={<Storage width={62} />}
        text={<FormattedHTMLMessage id="rice.feature1" />}
        style={{ width: "100%" }}
      />
      <RobotFeature
        icon={<Multimedia width={62} />}
        text={<FormattedHTMLMessage id="rice.feature2" />}
        style={{ width: "100%" }}
      />
      <RobotFeature
        icon={<SMS width={59} />}
        text={<FormattedHTMLMessage id="rice.feature3" />}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default RiceFeatures;
