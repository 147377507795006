import React from "react";
import { css } from "@emotion/core";

const Skeleton = ({ skeletonStyle }) => {
  return (
    <div
      css={css`
        display: inline-block;
        ${skeletonStyle}
        background: linear-gradient(
          -90deg,
          #424242 0%,
          #3f3f3f 50%,
          #424242 100%
        );
        background-size: 400% 400%;
      `}
    />
  );
};

export default Skeleton;
