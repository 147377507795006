import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";

const TitleBlock = ({ icon, title }) => {
  const intl = useIntl();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
        ${Tablet} {
          margin-bottom: 15px;
        }
        ${Desktop} {
          align-items: flex-start;
          margin-bottom: 0px;
        }
      `}
    >
      <div
        css={css`
          display: unset;
          margin-bottom: 20px;

          ${Tablet} {
            display: none;
          }
          ${Desktop} {
            display: unset;
            margin-bottom: 30px;
          }
        `}
      >
        {icon}
      </div>
      <h2
        css={css`
          display: inline-block;
          font-size: 20px;
          margin-bottom: 0;

          ${Tablet} {
            font-size: ${intl.locale === "en" ? 20 : 18}px;
          }
          ${Desktop} {
            font-size: ${intl.locale === "en" ? 25 : 22}px;
            margin-bottom: 15px;
          }
        `}
      >
        <b>{title}</b>
      </h2>
    </div>
  );
};

const TextBlock = ({ text }) => {
  return (
    <p
      css={css`
        text-align: left;

        ${Desktop} {
          line-height: 1.6;
        }
      `}
    >
      {text}
    </p>
  );
};

const FeatureCard = ({ icon, title, text }) => {
  const intl = useIntl();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        min-width: 280px;
        max-width: 450px;
        border: 2px solid #000;
        border-radius: 10px;
        flex: 1;
        ${Tablet} {
          flex-direction: row;
          max-width: 100%;
          align-items: center;
          min-width: 0px;
        }
        ${Desktop} {
          align-items: flex-start;
        }
      `}
    >
      <div
        css={css`
          display: none;

          ${Tablet} {
            display: unset;
            margin-left: 40px;
          }
          ${Desktop} {
            display: none;
          }
        `}
      >
        {icon}
      </div>

      <div
        css={css`
          margin-top: 0px;
          width: 100%;
          display: flex;
          flex-direction: column;

          ${Tablet} {
            text-align: left;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 30px;
            ${Tablet} {
              padding: 40px;
            }
            ${Desktop} {
              padding: ${intl.locale === "en" ? "40px" : "35px"};
            }
          `}
        >
          <TitleBlock title={title} icon={icon} />
          <TextBlock text={text} />
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
