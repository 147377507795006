import React, { useState } from "react";
import { useMount } from "react-use";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";

const Slogan = () => {
  const [showSlogan, setShowSlogan] = useState(false);

  useMount(() => {
    setTimeout(() => {
      setShowSlogan(true);
    }, 200);
  });

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        margin-top: 40px;

        ${Tablet} {
          margin-top: 20px;
        }
        ${Desktop} {
          margin-top: 40px;
        }
      `}
    >
      <h1
        css={css`
          font-size: 44px;
          width: 66%;
          margin-bottom: 16px;
          line-height: 1.2;
          opacity: ${showSlogan ? 1 : 0};
          transition: opacity 0.3s ease;
          ${Tablet} {
            width: 500px;
            font-size: 48px;
            margin-bottom: 16px;
          }
          ${Desktop} {
            font-size: 80px;
            width: 800px;
            margin-bottom: 30px;
          }
        `}
      >
        <FormattedHTMLMessage id="slogan" />
      </h1>
    </div>
  );
};

export default Slogan;
