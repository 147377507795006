import React from "react";
import Img from "gatsby-image";
import { Desktop, Tablet } from "../../constants/responsive";
import { css } from "@emotion/core";

export const AwardBoxHoverLayer = ({ award, year }) => {
  return (
    <div
      css={css`
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        font-weight: normal;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 20;
        opacity: 0;
        transition: all 0.2s ease;
        color: #fff;
        font-weight: 900;
        &:hover {
          opacity: 1;
          background: #212121dd;
        }
      `}
    >
      <h3
        css={css`
          margin-bottom: 25px;
        `}
      >
        {award}
      </h3>
      <h4>{year}</h4>
    </div>
  );
};

export const AwardImageBox = ({ award, year, fluid, children, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;

          position: relative;
          aspect-ratio: 1.75/1;
          ${Tablet} {
            aspect-ratio: 1;
          }
          ${Desktop} {
            aspect-ratio: 1;
          }
        `}
      >
        <AwardBoxHoverLayer award={award} year={year} />

        {fluid ? (
          <Img
            fluid={fluid}
            loading="eager"
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            fadeIn={false}
          />
        ) : (
          children
        )}
      </div>
    </a>
  );
};
