export const videos = [
  {
    id: "t_SJ-K8xNdY",
    client: "japan711",
    region: "japan",
    type: "youtube",
  },
  {
    id: "Zrmz7I5Y4fc",
    client: "tokyoMidtown",
    region: "japan",
    type: "youtube",
  },
  {
    id: "sAhlfFVqkxo",
    client: "taiseiCorporation",
    region: "japan",
    type: "youtube",
  },
  {
    id: "mitsui2",
    client: "mitsui2",
    region: "japan",
    type: "local",
  },
  {
    id: "4K6BdT8c2Hk",
    client: "mitsui1",
    region: "japan",
    type: "youtube",
  },
  {
    id: "OOlzcYixAI8",
    client: "hactl",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "KARFLiLWyAU",
    client: "hkcec2",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "softbank3",
    client: "softbank3",
    region: "japan",
    type: "local",
  },
  {
    id: "QBKrKnwdh_U",
    client: "softbank2",
    region: "japan",
    type: "youtube",
  },
  {
    id: "kZGNEN3aA4c",
    client: "softbank1",
    region: "japan",
    type: "youtube",
  },
  {
    id: "Bt8s1ICcQ34",
    client: "japanPost",
    region: "japan",
    type: "youtube",
  },
  {
    id: "HmCd324WpF0",
    client: "hkcec1",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "66hrCd8jp7g",
    client: "cyberport",
    region: "hongKong",
    type: "youtube",
  },

  {
    id: "2PogsWOszAQ",
    client: "tmtPlaza",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "VcV5VKRgP0Q",
    client: "sino",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "i4UzGGCwP5o",
    client: "otis",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "vKLawIOBvzs",
    client: "lHotel",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "CP9njM8rODk",
    client: "leMeridien",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "PlC5iB577gA",
    client: "handerson",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "EzQi799NuE8",
    client: "moko",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "jaHThM4mU_A",
    client: "skycity",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "8v8_iIy_Ypo",
    client: "dorsett",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "u20yGxkyLRU",
    client: "k11Musea",
    region: "hongKong",
    type: "youtube",
  },
  {
    id: "Qr5aGzEWG0s",
    client: "k11Atelier",
    region: "hongKong",
    type: "youtube",
  },
];
