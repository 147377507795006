import React from "react";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import CleanAir from "../../svgs/icons/clean-air.svg";
import Intercom from "../../svgs/icons/intercom.svg";
import Rec from "../../svgs/icons/rec.svg";
import Delivery from "../../svgs/icons/delivery.svg";
import Direction from "../../svgs/icons/direction.svg";
import Support from "../../svgs/icons/support.svg";
import Puddle from "../../svgs/icons/puddle.svg";
import Thermometer from "../../svgs/icons/thermometer.svg";
import RobotFeature from "../RobotFeature";
import { Desktop, Tablet } from "../../constants/responsive";

const PortalFeatures = () => {
  const style = {
    [Tablet]: {
      width: "330px",
    },
    [Desktop]: { width: "100%" },
  };
  return (
    <>
      <RobotFeature
        icon={<Rec width={62} />}
        text={<FormattedHTMLMessage id="portal.feature1" />}
        style={style}
      />
      <RobotFeature
        icon={<Intercom width={62} />}
        text={<FormattedHTMLMessage id="portal.feature2" />}
        style={style}
      />
      <RobotFeature
        icon={<Puddle width={62} />}
        text={<FormattedHTMLMessage id="portal.feature3" />}
        style={style}
      />
      <RobotFeature
        icon={<CleanAir width={62} />}
        text={<FormattedHTMLMessage id="portal.feature4" />}
        style={style}
      />
      <RobotFeature
        icon={<Thermometer width={62} />}
        text={<FormattedHTMLMessage id="portal.feature5" />}
        style={style}
      />
      <RobotFeature
        icon={<Support width={59} />}
        text={<FormattedHTMLMessage id="portal.feature6" />}
        style={style}
      />
      <RobotFeature
        icon={<Direction width={59} />}
        text={<FormattedHTMLMessage id="portal.feature7" />}
        style={style}
      />
      <RobotFeature
        icon={<Delivery width={59} />}
        text={<FormattedHTMLMessage id="portal.feature8" />}
        style={style}
      />
    </>
  );
};

export default PortalFeatures;
