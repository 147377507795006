import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { css } from "@emotion/core";
import YouTube from "react-youtube";
import { useWindowSize } from "react-use";
import SoftbankVideo from "../../video/softbank.mp4";
import MitsuiVideo from "../../video/mitsui.mp4";

const localVideos = { softbank3: SoftbankVideo, mitsui2: MitsuiVideo };

const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #000000e1;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 90;
        user-select: none;
      `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

const ModalBox = ({ handleClose, video }) => {
  const { width } = useWindowSize();

  let playerWidth;

  if (width > 1200) {
    playerWidth = 1024;
  } else if (width >= 768 && width <= 1280) {
    playerWidth = 720;
  } else if (width >= 580 && width < 768) {
    playerWidth = 560;
  } else if (width < 580) {
    playerWidth = width - 40;
  }

  const playerHeight = (playerWidth / 16) * 9;

  const opts = {
    width: playerWidth,
    height: playerHeight,
    playerVars: {
      controls: 1,
      modestbranding: 1,
      iv_load_policy: 3,
      loop: 1,
    },
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        css={css`
          width: auto;
          height: auto;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 80;
          & > div {
            margin-bottom: -5px;
          }
        `}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {video.current.type === "youtube" && (
          <YouTube
            videoId={video.current.id}
            opts={opts}
            onReady={(e) => {
              e.target.setVolume(100);
              e.target.unMute();
              e.target.playVideo();
            }}
          />
        )}
        {["softbank3", "mitsui2"].includes(video.current.id) && (
          <video width={playerWidth} height={playerHeight} controls autoPlay>
            <source src={localVideos[video.current.id]} type="video/mp4" />
          </video>
        )}
      </motion.div>
    </Backdrop>
  );
};

const Modal = ({ modalOpen, setModalOpen, video }) => {
  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter
      onExitComplete={() => null}
    >
      {modalOpen && (
        <ModalBox
          modalOpen={modalOpen}
          video={video}
          handleClose={() => {
            setModalOpen(false);
            video.current = null;
          }}
        />
      )}
    </AnimatePresence>
  );
};

export default Modal;
