import React, { useState } from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Desktop } from "../../constants/responsive";
import DownArrow from "../../svgs/icons/down-arrow.svg";
import InternalLink from "../InternalLink";

const RobotImage = ({
  fluid,
  imageStyle,
  eyeStyle,
  blinkingEyeImage,
  fadeInTime,
  robotName,
  popUpTextPosition,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      onMouseDown={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
      css={css`
        width: calc(1.68 * (30vw - 40px));
        transition: transform 0.2s ease-in-out, opacity 0.2s ease;
        position: relative;
        user-select: none;
        opacity: ${isVisible ? 1 : 0};
        &:hover .text {
          opacity: 1;
        }

        ${Desktop} {
          &:hover .blinking-eyes {
            opacity: 1;
          }
        }
        ${imageStyle}
      `}
    >
      <div
        css={css`
          position: absolute;
          ${eyeStyle}
        `}
      >
        {blinkingEyeImage}
      </div>

      <div
        className="text"
        css={css`
          opacity: 1;
          transition: opacity 0.3s ease;
          position: absolute;
          z-index: 14;
          ${popUpTextPosition};
          ${Desktop} {
            opacity: 0;
          }
        `}
      >
        <InternalLink
          to={`/#${robotName}`}
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: initial;
            opacity: 1;
            transition: opacity 0.2s ease;
            &:visited {
              color: initial;
            }
            &:hover {
              color: initial;

              opacity: 0.5;
            }
          `}
        >
          <h3
            css={css`
              font-weight: bold;
              text-align: center;
            `}
          >
            {robotName.toUpperCase()}
          </h3>
          <DownArrow
            css={css`
              height: 25px;
              width: 25px;
              margin-top: -8px;

              ${Desktop} {
                margin-top: -10px;
                height: 35px;
                width: 35px;
              }
            `}
          />
        </InternalLink>
      </div>

      <Img
        css={css`
          position: relative;
          z-index: 0;
        `}
        fluid={fluid}
        loading="eager"
        fadeIn={false}
        onLoad={() =>
          setTimeout(() => {
            setIsVisible(true);
          }, fadeInTime)
        }
      />
    </div>
  );
};

export default RobotImage;
