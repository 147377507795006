import React from "react";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import Time from "../../svgs/icons/time.svg";
import Schedule from "../../svgs/icons/schedule.svg";
import Spray from "../../svgs/icons/spray.svg";
import RobotFeature from "../RobotFeature";

const JasmineFeatures = () => {
  return (
    <>
      <RobotFeature
        icon={<Time width={64} />}
        text={<FormattedHTMLMessage id="jasmine.feature1" />}
        style={{ width: "100%" }}
      />
      <RobotFeature
        icon={<Schedule width={62} />}
        text={<FormattedHTMLMessage id="jasmine.feature2" />}
        style={{ width: "100%" }}
      />
      <RobotFeature
        icon={<Spray width={60} />}
        text={<FormattedHTMLMessage id="jasmine.feature3" />}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default JasmineFeatures;
