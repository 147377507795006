import React from "react";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";

const RobotFeature = ({ icon, text, style = {} }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        ${Tablet} {
          margin-bottom: 20px;
          margin-top: 0;
          ${style};
        }
        ${Desktop} {
          margin-top: 40px;
          max-width: 100%;
          ${style};
        }
      `}
    >
      <div
        css={css`
          min-width: 65px;
        `}
      >
        {icon}
      </div>
      <h3
        css={css`
          margin-left: 40px;
        `}
      >
        {text}
      </h3>
    </div>
  );
};

export default RobotFeature;
