import React from "react";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";
import Container from "../Container";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";

const RobotSection = ({
  name,
  children,
  image,
  sectionStyle,
  index,
  background,
}) => {
  return (
    <div
      id={name}
      css={css`
        background: ${background};
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: ${index};
      `}
    >
      <Container>
        <div
          css={css`
            min-width: 280px;
            max-width: 400px;
            margin-top: 40px;
            margin-bottom: 0px;
            margin-left: auto;
            margin-right: auto;
            ${sectionStyle}

            ${Tablet} {
              width: 100%;
              max-width: none;
              margin-top: auto;
              margin-bottom: auto;
              ${sectionStyle}
            }
            ${Desktop} {
              width: 38%;
              margin-left: ${index % 2 === 0 ? "62%" : "initial"};
              padding: 0px;
              ${sectionStyle};
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              position: relative;
              z-index: 5;
              ${Tablet} {
                flex-direction: ${name === "portal" ? "column" : "row"};
                justify-content: space-between;
              }
              ${Desktop} {
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                ${Tablet} {
                  width: ${name === "portal" ? "100%" : "50%"};
                }
                ${Desktop} {
                  width: 100%;
                }
              `}
            >
              <h1>
                <FormattedHTMLMessage id={`${name}.name`} />
              </h1>
              <h2>
                <FormattedHTMLMessage id={`${name}.type`} />
              </h2>
              <p>
                <FormattedHTMLMessage id={`${name}.text`} />
              </p>
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: ${name === "portal" ? "row" : "column"};
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 60px;
                max-width: 100%;

                ${Tablet} {
                  max-width: ${name === "portal" ? "100%" : "280px"};
                  margin-top: ${name === "portal" ? "40px" : 0};
                }
                ${Desktop} {
                  max-width: 100%;
                  margin-top: 20px;
                  margin-bottom: 20px;
                }
              `}
            >
              {children}
            </div>
          </div>
          <div
            onContextMenu={(e) => e.preventDefault()}
            onMouseDown={(e) => e.preventDefault()}
            css={css`
              position: relative;
            `}
          >
            {image}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RobotSection;
